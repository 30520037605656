#faqHeader{
    padding: 100px 150px 0px 150px;
    grid-area: faqHeader;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 33% 33% 33%;
    animation: gradient 8s infinite alternate;
    background-image: linear-gradient(30deg, #1A3361, #244787, #2C55A0, #2F5BAD, #407DED);
    background-size: 300% 300%;
    border-radius: 0px 0px 0px 150px;
    height: 40vh;
}
.faqHeader-title{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
}
.faqHeader-title h1{
    font-size: 40px;
    color: white;
}
.underlineFaq{
    position: relative;
    left: 50px;
}
.faqHeader-img{
    position: relative;
    top: -50px;
    left: 150px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
}
@media screen and (max-width: 1600px){
    .faqHeader-img img{
        width: 550px !important;
    }
}
@media screen and (max-width: 1400px){
    .faqHeader-title h1{
        width: 150% !important;
    }
}
@media screen and (max-width: 1000px) {
    #faqHeader{
        padding: 10% 5% 0% 5% !important;
    }
    #particle8{
        display: none;
    }   
}
@media screen and (max-width: 800px){
    #faqHeader{
        grid-template-columns: 100%;
        align-items: center;
    }
    .faqHeader-title h1{
        width: 100% !important;
    }
    .faqHeader-img{
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .faqHeader-title h1{
        font-size: 35px !important;
    } 
}

@media screen and (max-width: 425px) {
    .faqHeader-title h1{
        font-size: 30px !important;
    }
}


