#ourServices {
    overflow-x: hidden;
    z-index: 2;
    padding: 150px 150px 50px 150px;
    grid-area: ourServices;
}

.ourServices-text {
    z-index: 2;
    display: grid;
}

.ourServices-text p {
    width: 50vw;
}

.ourServices-btn {
    z-index: 2;
    padding: 10px 0px 50px 0px;
}

.ourServices-btn a:hover {
    background-image: linear-gradient(to right, white, white);
    -webkit-background-clip: none;
    -webkit-text-fill-color: none;
}

.ourServices-btn button {
    backdrop-filter: blur(5.5px);
    z-index: 2;
    width: 180px;
    border-radius: 50px;
    height: 40px;
    border: 1px solid var(--cor-azul-secundaria);
    background-color: rgba(255, 255, 255, 0.25);
    transition: 0.5s ease-in-out;
}

.ourServices-btn button:hover {
    transform: translateY(1px);
    box-shadow: 0px 10px 10px #71717199;
    border: none;
    background-image: linear-gradient(31deg, #2c55a0d0, #2268d0b9);
}

.ourServices-btn button a {
    background-image: linear-gradient(to right, #2c55a0, #2c55a0, #2c55a0, #2c55a0, rgb(81, 165, 255), #2c55a0, #2c55a0, #2c55a0, #2c55a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 250% 250%;
    animation: gradient 10s infinite;
    height: 100%;
    align-items: center;
    transition: 0.5s;
    z-index: 2;
    font-weight: 600;
    display: grid;
    width: 100%;
    color: var(--cor-azul-secundaria);
    font-size: 18px;
}

#border {
    background-color: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(5.5px);
    position: relative;
    z-index: 5;
    margin-left: -200px;
    border-radius: 0px 500px 500px 0px;
    border: 2px solid var(--cor-azul-principal);
}

.ourServices-cards {
    z-index: 2;
    margin-left: 200px;
    display: flex;
    padding: 4% 0% 4% 0%;
    gap: 10%;
}

#os-card-1 {
    z-index: 2;
    z-index: 5;
    width: 18vw;
}

#os-card-1 h2 {
    z-index: 2;
    font-size: 26px;
    font-weight: 600;
    color: var(--cor-azul-secundaria);
    background-image: linear-gradient(to right, rgb(0, 123, 255), #2c55a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#os-card-1 p {
    z-index: 2;
    padding: 5% 0%;
    font-size: 16px;
    color: var(--cor-azul-principal);
}

#os-card-2 {
    z-index: 2;
    width: 18vw;
}

#os-card-2 h2 {
    z-index: 2;
    font-size: 26px;
    font-weight: 600;
    color: var(--cor-azul-secundaria);
    background-image: linear-gradient(to right, rgb(0, 123, 255), #2c55a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#os-card-2 p {
    z-index: 2;
    padding: 5% 0%;
    font-size: 16px;
    color: var(--cor-azul-principal);
}

#os-card-3 {
    z-index: 2;
    width: 18vw;
}

#os-card-3 h2 {
    z-index: 2;
    font-size: 26px;
    font-weight: 600;
    color: var(--cor-azul-secundaria);
    background-image: linear-gradient(to right, rgb(0, 123, 255), #2c55a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#os-card-3 p {
    z-index: 2;
    padding: 5% 0%;
    font-size: 16px;
    color: var(--cor-azul-principal);
}

.ourServicesSlide1 {
    animation: ourServicesSlide1 1.5s ease-in-out;
}

.ourServicesSlide2 {
    animation: ourServicesSlide2 3s ease-in-out;
}

.ourServicesSlide3 {
    animation: ourServicesSlide2 2.5s ease-in-out;
}

.ourServicesSlide4 {
    animation: ourServicesSlide2 2s ease-in-out;
}

@keyframes ourServicesSlide1 {
    from {
        transform: translateX(-100vw);
    }
}

@keyframes ourServicesSlide2 {
    from {
        transform: translateX(-200vw);
    }
}

@media screen and (max-width: 1200px) {
    #ourServices {
        padding: 4% 8%;
    }
}

@media screen and (max-width: 1000px) {
    #ourServices {
        padding: 10% 5%;
    }

    .ourServices-cards {
        padding: 5% 0%;
        gap: 50px;
        display: grid;
    }

    #border {
        backdrop-filter: none;
        border-radius: 0px 200px 200px 0px;
    }

    #os-card-1 {
        gap: 15px;
        width: 60vw;
    }

    #os-card-1 h2 {
        font-size: 25px;
    }

    #os-card-1 p {
        padding: 0;
    }

    #os-card-2 {
        gap: 15px;
        width: 60vw;
    }

    #os-card-2 h2 {
        font-size: 25px;
    }

    #os-card-2 p {
        padding: 0;
    }

    #os-card-3 {
        gap: 15px;
        width: 60vw;
    }

    #os-card-3 h2 {
        font-size: 25px;
    }

    #os-card-3 p {
        padding: 0;
    }

    .ourServices-text p {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .ourServices-text p {
        width: 100%;
    }

    .ourServices-btn {
        padding: 15px 0px 50px 0px;
    }
}

@media screen and (max-width: 400px) {
    #border {
        border-radius: 0px 100px 100px 0px;
    }
}