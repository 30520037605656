#history {
    overflow-x: hidden;
    padding: 100px 150px 50px 150px;
    grid-area: history;
    background-repeat: no-repeat;
}

.align-about {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

#border-title {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    padding: 20px 0px;
    width: 600px;
    margin: 0px 0px 0px -200px;
    border: 1px solid var(--cor-azul-secundaria);
    border-radius: 0px 100px 100px 0px;
    background-image: linear-gradient(30deg, #ffffff, #a6a1fc10, #9f9aff13);
    -webkit-backdrop-filter: blur(5.5px);
    backdrop-filter: blur(3.5px);
}

#border-title h1 {
    font-size: 40px;
    width: fit-content !important;
    background-image: linear-gradient(to right, #407DED, #2F5BAD, #2c60c2, #2F5BAD, #407DED);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 350% 350%;
    animation: gradient 10s infinite;
    margin-left: 200px;
}

#border-text {
    max-width: 600px;
    min-width: 250px;
    display: grid;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    width: 90%;
}

#border-text p {
    align-self: start;
    width: 100%;
    padding: 50px 0px;
}

#video-border {
    justify-self: center;
    max-width: 800px;
    min-width: 500px;
    justify-content: center;
    grid-column-start: 2;
    grid-column-end: 4;
    width: 100%;
    height: fit-content;
    display: grid;
    padding: 10px;
}

#history-video {
    padding: 20px;
    display: grid;
    align-items: center;
    justify-content: center;
}

.background-video {
    background-image: url('https://singlec.com.br/imagens-publicas/fundo_video.png');
    background-position-x: -10px;
    background-position-y: -40px;
    background-repeat: no-repeat;
    position: relative;
    background-size: 100% 100%;
    height: 50vh;
    width: 100%;
}

#history-video video {
    border: 2px solid #7457F2;
    padding: 15px;
    justify-self: center;
    height: 100vh;
    max-height: 300px;
    width: 100%;
    max-width: 100%;
    border-radius: 50px;
    transition-property: border-radius, scale;
    transition-duration: 0.4s;
}

#history-video video:hover {
    border-radius: 50px;
    scale: 1.05;
}

@media screen and (max-width: 1400px) {
    #history-video video:hover {
        border-radius: 50px;
        scale: 1.0;
    }

    #history-video iframe:hover {
        border-radius: 50px;
        scale: 1;
    }

    #history {
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        justify-content: center;
        padding: 10% 5%;
    }

    #border-text {
        grid-column-end: 4;
    }

    #video-border {
        grid-row-start: 3;
        grid-row-end: 4;
        margin: 0;
        grid-column-start: 1;
    }
}

@media screen and (max-width: 600px) {
    #border-title {
        width: fit-content;
        padding: 10px 20px;
    }

    #border-text {
        text-align: center;
        justify-self: center;
    }

    #border-text p {
        padding: 20px 0px;
    }

    #video-border {
        min-width: auto;
    }
}

@media screen and (max-width: 900px) {
    .align-about {
        flex-direction: column-reverse;
    }

    #border-text p {
        padding: 10px 0px;
    }
}