nav{
    width: 100vw;
    position: fixed;
    display: grid;
}
.active{
    visibility: visible !important;
}
#burguer-btn{
    visibility: hidden;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    display: flex;
    border: 0;
    background-color: transparent;
}

#burguer-btn img{
    width: 30px;
}

#menu{
    border-radius: 0px 0px 30px 30px;
    top: 0%;
    align-items: center;
    z-index: 15;    
    padding: 0% 6% 0% 6%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;                
    height: 70px;
    width: 100%;
}

#menu ul{
    align-items: center;
    display: flex;
    gap: 1.2rem;
}

#menu .link{
    display: grid;
    align-items: center;
    font-weight: 300;
    color: white;
}

#menu a img{
    filter: brightness(100);
    width: 180px;
}

.nav-btn{
    cursor: pointer;
    border-radius: 50px;
    border: 1.5px solid white;
    background-color: rgba(160, 160, 160, 0);
}

.nav-btn p{
    margin: 0px;
    padding: 5px 20px;
    font-size: 15px;
    color: white;
}

@media screen and (max-width: 1000px){
    #menu ul{
        width: 100vw;
        gap: 50px;
        background-color: #2c55a0a4;
        visibility: hidden;
        top: 69px;
        padding: 30px 0px;
        left: 0px;
        position: absolute;
        flex-direction: column;
    }
    #menu ul .link{
        font-weight: 600;
    }
    #burguer-btn{
        visibility: visible;
    }
    #menu.active .nav-menu{
        visibility: visible;
        animation: fromTop 0.6s ease-in-out;
    }
}

@media screen and (max-width: 375px){
    .hero-cont{
        display: grid !important;
        gap: 50px;
    }
    .hero-cont h1{
        font-size: 35px !important;
    }
}
@keyframes fromTop {
    from{
        transform: translateY(-70vh);
    }
}