#allServices{
    grid-area: allServices;
    display: grid;
    padding: 200px 150px 150px;
    align-items: center;
    gap: 100px;
}
#allServices p{
    font-size: 20px !important;
    color: var(--cor-azul-secundaria);
}
#allServices h1{
    width: fit-content;
    font-size: 32px !important;
}
#allServices h2{
    color: white !important;
}
.timeline{
    justify-self: center;
    height: 50%;
    width: 2px;
    border-radius: 30px;
    background-color: var(--cor-azul-secundaria);
}
.service{
    grid-template-columns: 45% 10% 45%;
    display: grid;
    align-items: center;
}
.service-img{
    max-width: 1000px;
    max-height: auto;
    display: grid;
    align-items: center;
    justify-content: center;
}
.service-title{
    gap: 20px;
    display: flex;
}

/* SERVICE 1 */

.service1{
    grid-template-areas: 'img1 timeline text1';
    text-align: left;
}
.service1-img{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: 100%;
    justify-self: right;
    grid-area: img1;
}
.service1-text{
    grid-area: text1;
}
.tablet{
    position: relative;
    margin: 0px -50px -50px 0px ;
    width: 192px;
    height: 224px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    z-index: 2;
    transition: 0.4s;
}
.tablet:hover{
    scale: 1.15;
}
.phone{
    position: relative;
    margin: 0px -80px -200px 0px;
    width: 112px;
    height: 220px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    z-index: 3;
    transition: 0.5s;
}
.phone:hover{
    scale: 1.15;
}
.laptop{
    width: 400px;
    height: 240px;
    z-index: 1;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    transition: 0.4s ease-in-out;
}
.laptop:hover{
    scale: 1.15;
}

/* SERVICE 2 */

.service2{
    grid-template-areas: 'text2 timeline img2';
    text-align: right;
}
.service2-title{
    align-items: center;
    justify-content: flex-end;
}
.service2-text{
    display: grid;
    grid-area: text2;
}
.service2-text h1{
    justify-self: flex-end;
    display: grid;
}
.service2-img{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    justify-self: left;
    grid-area: img2;
}
.site1{
    position: relative;
    margin: 0px 0px 10px 30px;
    width: 325px;
    height: 200px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    z-index: 2;
    transition: 0.5s;
}
.site1:hover{
    scale: 1.15;
}
.site2{
    position: relative;
    width: 325px;
    height: 200px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    z-index: 3;
    transition: 0.5s;
}
.site2:hover{
    scale: 1.15;
}
.laptopSites{
    margin: 0px 0px -250px -20px;
    width: 400px;
    height: 240px;
    z-index: 1;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    transition: 0.5s;
}
.laptopSites:hover{
    scale: 1.15;
}

/* SERVICE 3 */

.service3{
    grid-template-areas: 'img3 timeline text3';
    text-align: left;
}
.service3-img{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: 100%;
    justify-self: right;
    grid-area: img3;
}
.service3-text{
    grid-area: text3;
}
.phoneMarketing1{
    width: 165px;
    height: 308px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    transition: 0.5s;
}
.phoneMarketing1:hover{
    scale: 1.15;
}
.phoneMarketing2{
    width: 195px;
    height: 364px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    transition: 0.5s;
}
.phoneMarketing2:hover{
    scale: 1.15;
}
.phoneMarketing3{
    width: 165px;
    height: 308px;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    transition: 0.5s;
}
.phoneMarketing3:hover{
    scale: 1.15;
}
/* SERVICE 4 */

.service4{
    grid-template-areas: ' text4 timeline img4';
    text-align: right;
}
.service4-text{
    display: grid;
    grid-area: text4;
}
.service4-title{
    align-items: center;
    justify-content: flex-end;
}
.service4-text h1{
    justify-self: flex-end;
    display: grid;
}
.service4-img{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 100%;
    justify-self: left;
    grid-area: img4;
}
.email{
    position: relative;
    margin: 0px 0px 0px -120px;
    width: 500px;
    height: 300px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    transition: 0.5s;
    }
.email:hover{
    scale: 1.15;
}
.email2{
    position: relative;
    margin: 0px 0px -50px 0px;
    width: 160px;
    height: 200px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    transition: 0.5s;
    }
.email2:hover{
    scale: 1.15;
}
.item{
    justify-content: center;
    display: grid;
    align-self: center;
    justify-self: center;
    border-radius: 30px;
    height: fit-content;
    width: 45px;
    background-image: linear-gradient(45deg, #4989ff, #584ffee5, #4989ff);
}
@media screen and (max-width: 1600px){
    .laptopSites{
        margin: 0px 0px -250px -120px;
    }
}
@media screen and (max-width: 1100px){
    #allServices{
        padding: 15% 8% 10% 8%;
    }
    #allServices img{
        justify-self: center;
    }
    .timeline{
        display: none !important;
    }
    .service{
        gap: 20px;
        display: grid;
        grid-template-columns: 100% !important;
        grid-template-rows: auto auto;
        text-align: center;
    }
    .service-title{
        grid-template-columns: auto auto;
        grid-template-rows: auto;
        display: grid;
        justify-self: center;
    }
    .item{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    }
    .service-title h1{
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
    }
    .service-img{
        justify-self: center !important;
        display: grid;
        width: 100%;
        grid-column-start: 1;
        grid-column-end: 2 !important; 
        grid-row-start: 1;
        grid-row-end: 2;
    }
    .service-text{
        display: grid;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
    }
    .service1-img{
        position: relative;
        margin-bottom: 80px;
    }
}
@media screen and (max-width: 768px){
    .service1-img{
        width: 200px;
        margin: 0px;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
    }
    .tablet{
        margin: -200px -400px 0px 0px;
        grid-column-start: 1;
        grid-row-start: 3;
        grid-row-end: 4;
    }
    .phone{
        margin: -150px 0px 0px -100px;
        grid-column-start: 1;
        grid-row-start: 2;
        grid-row-end: 3;
    }
    .laptop{
        margin: 0;
        grid-column-start: 1;
        grid-row-start: 1;
        grid-row-end: 2;
    }
    .service2-img{
        width: 200px;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
    }
    .site1{
        z-index: 5;
        margin: 100px -150px 0px 0px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
    }
    .site2{
        margin: -50px 0px 0px 0px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
    }
    .laptopSites{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    }
    .service3-img{
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
    }
    .phoneMarketing1{
        margin: 0px 0px 0px -250px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    }
    .phoneMarketing2{
        z-index: 5;
        margin: -200px 0px 0px 0px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
    }
    .phoneMarketing3{
        margin: -200px -250px 0px 0px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
    }
    .service4-img{
        width: 100px;
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
    }
    .email{
        width: 450px;
        margin: 0px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    }
    .email2{
        margin: -200px 0px 0px -200px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
    }
}
@media screen and (max-width:425px){
    .item{
        display: none;
    }
    
}