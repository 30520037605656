#ourPlatforms{
    z-index: 2;
    padding: 50px 150px 100px 150px;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-area: ourPlatforms;
    display: grid;
    gap: 60px 20px;
}

#oP-title{
    height: fit-content;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
}

#oP-title h1{
    position: relative;
}

.oP-btn{
    padding: 10px 0px;
    z-index: 2;
}

.oP-btn a:hover{
    background-image: linear-gradient(to right, white, white);
    -webkit-background-clip: none;
    -webkit-text-fill-color: none;
    z-index: 2;
}

.oP-btn button{
    position: relative;
    -webkit-backdrop-filter: blur( 5.5px );
    backdrop-filter: blur( 5.5px );
    z-index: 2;
    width: 180px;
    border-radius: 50px;
    height: 40px;
    border: 1px solid var(--cor-azul-secundaria);
    background-color: transparent;
    transition: 0.5s ease-in-out;
}
.oP-btn button:hover{
    z-index: 2;
    transform: translateY(1px);
    box-shadow: 0px 10px 10px #71717199;
    border: none;
    background-image: linear-gradient(31deg,#2c55a0d0,#2268d0b9);
}

.oP-btn button a{
    background-image: linear-gradient(to right, #2c55a0, #2c55a0, #2c55a0, #2c55a0, rgb(81, 165, 255),#2c55a0,#2c55a0,#2c55a0, #2c55a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 250% 250%;
    animation: gradient 10s infinite;
    z-index: 2;
    transition: 0.5s;
    height: 100%;
    align-items: center;
    font-weight: 600;
    display: grid;
    width: 100%;
    color: var(--cor-azul-secundaria);
    font-size: 18px;
}
#oP-arch{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    display: grid;
}
.oP-arch-title{
    grid-column-start: 1;
    grid-column-end: 2;
}
.oP-arch-title h2{
    letter-spacing: -2px;
}

.count{
    z-index: 5;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-areas: 'count';
}
.count-content{
    margin: -100px 0px 0px -100px;
    justify-self: center;
    align-self: center;
    grid-area: count;
    background-color:#3270e4;
    backdrop-filter: blur( 5.5px );
    padding: 10px;
    display: grid;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    width: 200px;
    height: 200px;
}
.count-content h1{
    font-size: 30px;
    align-self: end;
    justify-self: center !important;
    height: fit-content !important;
    width: fit-content !important;
    color: white !important;
}
.count-content p{
    font-size: 20px;
    align-self: start !important;
    height: fit-content !important;
    width: 100% !important;
    color: white !important;
}
#oP-arch h1{
    position: relative;
    font-size: 32px;
    color: var(--cor-azul-secundaria);
    padding: 0px !important;
}

#oP-arch h2{   
    padding-bottom: 10px;
    position: relative;
    z-index: 2;
    margin-top: -8px; 
    font-size: 38px;
    font-weight: 600;
    color: #3270e4;
}

#oP-arch p{
    width: 420px;
    position: relative;
    z-index: 2;
    padding: 1% 0%;
    font-size: 18px;
    color: var(--cor-azul-principal);
}

#oP-business{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
}

#oP-business h1{
    padding: 10px 0px;
    position: relative;
    z-index: 2;
    margin-top: -8px; 
    font-size: 38px;
    font-weight: 600;
    color: #3270e4;
}

#oP-business p{
    width: 470px;
    position: relative;
    z-index: 2;
    padding: 1% 0%;
    font-size: 18px;
    color: var(--cor-azul-principal);
}
.oP-img{
    position: relative;
    top: 50px;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 4;
    grid-template-rows: 50% 50%;
    display: grid;
}
.oP-img1{
    position: relative;
    margin: 80px 0px 0px 0px;
    transition: 0.5s;
}
.oP-img1:hover{
    scale: 1.1;
}
.oP-img2{
    position: relative;
    margin: 0px 0px 0px -100px;
    transition: 0.5s;
}
.oP-img2:hover{
    scale: 1.1;
}
@media screen and (max-width: 1500px) {
    .count-content{
        position: relative;
        left: 20px;
        top: 10px;
        width: 150px;
        height: 150px;
    }
    .count-content h1{
        font-size: 25px;
    }
    .count-content p{
        font-size: 16px;
    }
    .oP-img{
        top: 0;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 4;
    }
    .oP-img1{
        width: 400px !important;
        margin: 0px 0px 0px 0px;
    }
    .oP-img2{
        margin-bottom: -50px;
        width: 400px !important;
    }
}
@media screen and (max-width: 1200px){
    #ourPlatforms{
        grid-template-columns: auto auto;
        padding: 4% 8%;
    }
    .oP-img{
        grid-column-start: 2;
        grid-column-end: 3;
    }
    .count{
        display: none;
    }
    .oP-img2{
        margin: 0px;
    }
}

@media screen and (max-width: 1000px){
    #ourPlatforms{
        padding: 10% 5%;
    }
    .oP-img1{
        width: 350px !important; 
    }
    .oP-img2{
        width: 350px !important; 
    }
}
@media screen and (max-width: 800px) {
    #ourPlatforms{
        grid-template-columns: 100%;
    }
    #oP-arch{
        grid-row-start: 2;
        grid-row-end: 3;
    }
    #oP-business{
        grid-row-start: 3;
        grid-row-end: 4;
    }
    .oP-img{
        display: none;
    }
}

@media screen and (max-width: 600px){
    #ourPlatforms{
        gap: 20px;
    }
.oP-btn{
    padding: 0px 0px;
}
#oP-arch h1{
    font-size: 25px;
}
#oP-arch h2{
    font-size: 30px;
}
#oP-arch p{
    width: 100%;
}
#oP-business h1{
    font-size: 25px;
}
#oP-business h2{
    font-size: 30px;
}
#oP-business p{
    width: 100%;
}
}