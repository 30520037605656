#policies{
    border-radius: 0px 200px 0px 0px;
    display: grid;
    padding: 100px 150px 50px 150px;
    grid-area: policies;
}
#policies-title{
    display: grid;
    text-align: center;
    justify-content: center;
}
#policies-title h1{
    font-size: 40px;
}
.underline{
    margin: -10px 0px 30px;
    justify-self: center;
    height: 3px;
    width: 200px;
    border-radius: 10px;
    background-image: linear-gradient(#4989ff, #584ffee5, #4989ff);
}
#policies-cards{
    grid-template-columns: auto auto auto;
    padding: 100px 0px;
    display: grid;
    text-align: center;
    grid-template-columns: auto auto auto;
    gap: 30px;
    justify-content: center;
}
.p-card{
    text-align: center;
    border-radius: 50px;
    background-color: #f6f9ff27;
    backdrop-filter: blur( 5.5px );
    box-shadow: 0 8px 32px 0 #2268d036;
    display: grid;
    padding: 10%;
    transition: 0.4s ease-in-out;
}
.p-card:hover{
    scale: 0.95;
}
.p-card h2{
    background-image: linear-gradient(to right, #4989ff, #584ffee5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: fit-content;
    padding: 10px;
    color: var(--cor-azul-secundaria) !important;
}
.p-card p{
    background-image: linear-gradient(45deg, #407DED, #2F5BAD, #2C55A0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
}
.p-card1{
    position: relative;
    top: 30px;
}
.p-card2{
    position: relative;
    top: -20px;
}
.p-card3{
    position: relative;
    top: 30px;
}
@media screen and (max-width: 1200px){
    #policies{
        padding: 0% 5% 10% 5%;
    }
}
@media  screen and (max-width: 1000px){
    .p-card:hover{
        scale: 1;
    }
    #policies-cards{
        grid-template-columns: auto auto;
    }
    .p-card{
        padding: 30px;
    }
    .p-card1{
        top: 0;
    }
    .p-card2{
        top: 0;
    }
    .p-card3{
        top: 0;
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

@media  screen and (max-width: 600px){
    #policies-cards{
        padding: 50px 0px 10px 0px;
        grid-template-columns: auto;
    }
    .p-card3{
        grid-column-end: 2;
    }
    .p-card p{
        font-size: 18px !important;
    }
}
