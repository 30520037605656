#business {
    padding: 150px 0px 70px 0px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-area: business;
}

.business-title {
    padding: 0px 0px 0px 150px;
    display: grid;
    grid-column-start: 1;
    grid-column-end: 2;
    gap: 10px;
}

.business-title h2 {
    font-size: 22px;
    color: var(--cor-azul-secundaria);
}

.business-title p {
    font-size: 18px;
    padding: 15px 0px 0px 0px;
    color: var(--cor-azul-principal);
}

.underline3 {
    width: 100px;
    height: 3px;
    position: relative;
    top: -20px;
    border-radius: 30px;
    background-color: var(--cor-azul-secundaria);
}

.business-img1 {
    align-self: center;
    padding: 0px 140px 0px 0px;
    justify-self: center;
}

.modules {
    z-index: 5;
    grid-column-start: 1;
    grid-column-end: 3
}

.modules-title {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 70px 0px 0px 0px;
    justify-content: center;
    text-align: center;
}

.modules-title h1 {
    font-size: 32px;
    color: var(--cor-azul-principal);
}

.underline4 {
    position: relative;
    top: 5px;
    height: 3px;
    width: 200px;
    border-radius: 30px;
    background-color: var(--cor-azul-secundaria);
    justify-self: center;
}

#modules-cards {
    height: 29vh;
    justify-content: center;
    padding: 50px 0px 0px 0px;
    display: grid;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-template-columns: repeat(8, 1fr);
}

.module {
    transition: 0.4s;
    height: 20%;
    grid-template-rows: 20% 60% 20%;
    padding: 20px 0px;
    gap: 20px;
    display: grid;
    justify-content: center;
}

.module:hover {
    height: 300px;
}

.module:focus {
    height: 300px;
}

.module:hover.module p {
    opacity: 100;
}

.module h2 {
    position: relative;
    text-align: center;
    font-size: 22px;
    color: white;
}

.description p {
    opacity: 0;
    font-weight: 500;
    color: white;
    padding: 0px 10px;
    font-size: 18px;
    text-align: center;
}

.status p {
    opacity: 0;
    color: white;
    padding: 0px 10px;
    font-size: 18px;
    text-align: center;
}

.module1 {
    background-color: #1A3361;
}

.module2 {
    background-color: #2B54A1;
}

.module3 {
    background-color: #407DED;
}

.module4 {
    background-color: #2F5BAD;
}

.module5 {
    background-color: #244787;
}

.module6 {
    background-color: #2B54A1;
}

.module7 {
    background-color: #2F5BAD;
}

.module8 {
    background-color: #2268d0;
}

.business-img2 {
    align-self: center;
    padding-left: 150px;
}

.modules-text {
    padding: 100px 150px 100px 0px;
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    justify-content: right;
}

.modules-text p {
    font-size: 18px;
}

@media screen and (max-width:1600px) {
    .module:hover {
        height: 350px !important;
    }

    .business-img1 {
        padding: 0px 140px 0px 0px;
    }

    .business-img1 img {
        width: 550px;
    }

    .business-img2 {
        padding: 0px 0px 0px 140px;
    }

    .business-img2 img {
        width: 550px;
    }
}

@media screen and (max-width:1440px) {
    .module:hover {
        height: 450px !important;
    }

    .business-img1 {
        padding: 0px 0px 0px 0px;
    }

    .business-img2 {
        padding: 0px 0px 0px 50px;
    }

    .modules-text {
        padding: 100px 50px 100px 0px;
    }
}

@media screen and (max-width:1200px) {
    #modules-cards {
        height: auto;
        grid-template-columns: auto auto auto auto;
    }

    .module {
        height: 280px !important;
    }

    .module:hover {
        height: 280px !important;
    }

    .module p {
        opacity: 100;
    }

    .module5 {
        background-color: #2B54A1 !important;
    }

    .module6 {
        background-color: #244787 !important;
    }

    .business-img2 img {
        width: 450px;
    }
}

@media screen and (max-width: 1100px) {
    #business {
        grid-template-columns: 100%;
        padding: 0px;
    }

    .business-title {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
        padding: 50px;
    }

    .business-img1 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
    }

    .modules {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
    }

    .business-img2 {
        justify-self: center;
        padding: 50px 0px 0px 0px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 4;
        grid-row-end: 5;
    }

    .modules-text {
        padding: 50px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 5;
        grid-row-end: 6;
    }

    .business-img2 img {
        width: 550px;
    }
}

@media screen and (max-width:900px) {
    #modules-cards {
        grid-template-columns: auto auto;
    }

    .module {
        grid-template-rows: 15% 50% 30%;
        height: 180px !important;
    }

    .module:hover {
        height: 180px !important;
    }

    .description {
        height: 100px;
    }
}

@media screen and (max-width: 700px) {
    .module {
        grid-template-rows: 20% 50% 30%;
        padding: 20px;
        height: 220px !important;
    }

    .module:hover {
        height: 220px !important;
    }
}

@media screen and (max-width: 600px) {
    .business-title h2 {
        font-size: 22px !important;
    }

    #modules-cards {
        grid-template-columns: auto !important;
    }

    .module {
        grid-template-rows: 20% 45% 15%;
        height: 150px !important;
    }

    .module h2 {
        font-size: 20px !important;
    }

    .module p {
        font-size: 16px !important;
    }

    .business-img1 img {
        width: 400px;
    }

    .business-img2 img {
        width: 400px;
    }
}