@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800&display=swap');

:root {
    --cor-azul-principal: #2c55a0;
    --cor-azul-secundaria: #5f8fe6;
    --cor-roxo: #8c00ff;
    --cor-azul-escuro: #051E6E;
    --cor-cinza: rgb(120, 120, 120);
    --cor-branca: #ffffff;
}

html {
    cursor: default;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

* {
    padding: 0%;
    margin: 0%;
    font-family: 'Nunito Sans', sans-serif;
}

body {
    background-image: linear-gradient(to right, white, rgb(230, 230, 230), rgb(230, 230, 230), #5f8ee648);
}

h1 {
    letter-spacing: -0.6px;
    width: 100%;
    font-size: 34px;
    color: var(--cor-azul-principal);
    z-index: 2;
    font-weight: 400 !important;
    padding: 10px 0px;
}

h2 {
    letter-spacing: -0.6px;
    font-size: 25px;
    z-index: 2;
    font-weight: 500 !important;
}

p {
    letter-spacing: -0.4px;
    font-size: 18px;
    color: var(--cor-azul-principal);
    font-weight: 500 !important;
}

a {
    letter-spacing: -0.4px;
    text-decoration: none;
}

li {
    list-style: none !important;
}

.home {
    overflow-x: hidden !important;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
        'header header header header header header header header header header header header'
        'ourServices ourServices ourServices ourServices ourServices ourServices ourServices ourServices ourServices ourServices ourServices ourServices '
        'ourPlatforms ourPlatforms ourPlatforms ourPlatforms ourPlatforms ourPlatforms ourPlatforms ourPlatforms ourPlatforms ourPlatforms ourPlatforms ourPlatforms'
        'medias medias medias medias medias medias medias medias medias medias medias medias'
        'why why why why why why why why why why why why'
        'budget budget budget budget budget budget budget budget budget budget budget budget'
        'footer footer footer footer footer footer footer footer footer footer footer footer'
        'particles particles particles particles particles particles particles particles particles particles particles particles'
        'buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons';
}

.about {
    overflow-x: hidden;
    background-image: linear-gradient(to right, white, rgb(230, 230, 230), rgb(230, 230, 230), #b6cfff48);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
        'nav nav nav nav nav nav nav nav nav nav nav nav'
        'aboutHeader aboutHeader aboutHeader aboutHeader aboutHeader aboutHeader aboutHeader aboutHeader aboutHeader aboutHeader aboutHeader aboutHeader'
        'history history history history history history history history history history history history'
        'context context context context context context context context context context context context'
        'policies policies policies policies policies policies policies policies policies policies policies policies'
        'footer footer footer footer footer footer footer footer footer footer footer footer'
        'buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons';
}

.services {
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
        'nav nav nav nav nav nav nav nav nav nav nav nav'
        'servicesHeader servicesHeader servicesHeader servicesHeader servicesHeader servicesHeader servicesHeader servicesHeader servicesHeader servicesHeader servicesHeader servicesHeader'
        'allServices allServices allServices allServices allServices allServices allServices allServices allServices allServices allServices allServices'
        'footer footer footer footer footer footer footer footer footer footer footer footer'
        'buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons';
}

.platforms {
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
        'nav nav nav nav nav nav nav nav nav nav nav nav'
        'platformsHeader platformsHeader platformsHeader platformsHeader platformsHeader platformsHeader platformsHeader platformsHeader platformsHeader platformsHeader platformsHeader platformsHeader'
        'arch arch arch arch arch arch arch arch arch arch arch arch'
        'business business business business business business business business business business business business'
        'footer footer footer footer footer footer footer footer footer footer footer footer'
        'buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons';
}

.faq {
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
        'nav nav nav nav nav nav nav nav nav nav nav nav'
        'faqHeader faqHeader faqHeader faqHeader faqHeader faqHeader faqHeader faqHeader faqHeader faqHeader faqHeader faqHeader'
        'questions questions questions questions questions questions questions questions questions questions questions questions'
        'footer footer footer footer footer footer footer footer footer footer footer footer'
        'buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons buttons';
}

.header {
    z-index: 15;
    grid-area: header;
    display: grid;
}

.arrows {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    align-self: end;
    height: fit-content;
    bottom: 0px;
    justify-content: center;
    display: grid;
    position: relative;
}

.arrow-about {
    grid-area: arrow;
    grid-row-start: 3;
    bottom: 30px;
    z-index: 3;
    scale: 0.8;
    position: relative;
    justify-content: center;
    display: grid;
}

.arrow2-about {
    grid-area: arrow;
    bottom: 20px;
    z-index: 3;
    scale: 0.9;
    position: relative;
    justify-content: center;
    display: grid;
}

.arrow3-about {
    grid-area: arrow;
    bottom: 10px;
    height: fit-content;
    z-index: 3;
    position: relative;
    justify-content: center;
    display: grid;
}

.arrow-about img {
    width: 50px;
    animation: isBouncing 1s ease-in-out infinite alternate;
    opacity: 0.2;
}

.arrow2-about img {
    animation: isBouncing2 1s ease-in-out infinite alternate 0.1s;
    width: 50px;
    opacity: 0.6;
}

.arrow3-about img {
    animation: isBouncing3 1s ease-in-out infinite alternate 0.2s;
    width: 50px;
    opacity: 1;
}

.underline2 {
    display: grid;
    justify-self: left;
    width: 250px;
    height: 3px;
    border-radius: 30px;
    background-image: linear-gradient(to right, #584ffee5, #4989ff);
}

@media screen and (max-width: 768px) {
    .container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas:
            'header'
            'ourServices'
            'ourPlatforms'
            'medias'
            'why'
            'budget'
            'footer';
    }

    .about {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas:
            'nav'
            'aboutHeader'
            'history'
            'context'
            'policies'
            'footer'
            'buttons';
    }

    .services {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas:
            'nav'
            'servicesHeader'
            'allServices'
            'footer'
            'buttons';
    }

    .platforms {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas:
            'nav'
            'platformsHeader'
            'arch'
            'business'
            'footer'
            'buttons';
    }

    .faq {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas:
            'nav'
            'faqHeader'
            'questions'
            'footer'
            'buttons';
    }
}

@media screen and (max-width: 600px) {
    h1 {
        font-size: 30px !important;
        padding: 15px 0px !important;
    }

    h2 {
        font-size: 25px !important;
    }

    p {
        font-size: 20px !important;
    }
}

@keyframes gradient {
    0% {
        background-position: 10%;
    }

    50% {
        background-position: 45%;
    }

    100% {
        background-position: 90%;
    }
}

@keyframes isBouncing {
    to {
        transform: translateY(20px);
    }
}

@keyframes isBouncing2 {
    to {
        transform: translateY(20px);
    }
}

@keyframes isBouncing3 {
    to {
        transform: translateY(20px);
    }
}