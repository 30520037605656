#buttons{
    z-index: 15;
    grid-area: buttons;
    display: grid;
    position: fixed;
    right: 20px;
    bottom: 20px;
    gap: 12px;
}
.instaLogo{
    padding: 10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    outline: 2px solid #85c4ff;
    outline-offset: 1px;
    transition-property: outline-offset, outline-color, background-color;
    transition-duration: 0.25s;
    background-image: linear-gradient(31deg,#2dabffb7,#2268d0d2,#2268d0ea);
}
.instaLogo:hover{
    outline-offset: 3px;
    animation: shake 0.5s;
    background-image: radial-gradient(
		circle at 30% 107%,
		#fdf497 0%,
		#fdf497 5%,
		#fd5949 45%,
		#d6249f 60%,
		#285aeb 90%
	);
	outline-color: #a02d76;
}
.instaLogoAfter{
    animation: insta2 ease-in .8s .2s forwards;
    padding: 10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    outline-offset: 1px;
    transition-property: outline-offset, outline-color, background-color;
    transition-duration: 0.25s;
    background-image: linear-gradient(31deg,#2dabff75,#2268d08a,#2268d073);
}
@keyframes insta2 {
    0%{
        transform: translate(0);
    }
    50%{
        transform: translateY(113px);
    }
    100%{
        transform: translate(-140px,113px);
    }
}
.wappLogo{
    padding: 10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    outline: 2px solid #85c4ff;
    outline-offset: 1px;
    transition-property: outline-offset, outline-color, background-color;
    transition-duration: 0.25s;
    background-image: linear-gradient(31deg,#2dabffb7,#2268d0d2,#2268d0ea);
}
.wappLogo:hover{
    background-image: linear-gradient(to top,#16a897, #25d366);
	outline-color: #25d366;
    outline-offset: 3px;
    animation: shake 0.5s;
}
.wappLogoAfter{
    background-image: linear-gradient(31deg,#2dabff75,#2268d08a,#2268d073);
    padding: 10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    outline-offset: 1px;
    transition-property: outline-offset, outline-color, background-color;
    transition-duration: 0.25s;
    animation: wapp2 0.7s linear forwards;
}
@keyframes wapp2 {
    0%{
        transform: translate(0);
    }
    50%{
        transform: translateY(56px);
    }
    100%{
        transform: translate(-210px, 56px);
    }
}
.linkLogo{
    padding: 10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    outline: 2px solid #85c4ff;
    outline-offset: 1px;
    transition-property: outline-offset, outline-color, background-color;
    transition-duration: 0.25s;
    background-image: linear-gradient(31deg,#2dabffb7,#2268d0d2,#2268d0ea);
}
.linkLogo:hover{
    background-color: #0077B5;
	outline-color: #0077B5;
    outline-offset: 3px;
    animation: shake 0.5s;
}
.linkLogoAfter{
    background-image: linear-gradient(31deg,#2dabff75,#2268d08a,#2268d073);
    padding: 10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    outline-offset: 1px;
    transition-property: outline-offset, outline-color, background-color;
    transition-duration: 0.25s;
    animation: link2 .5s linear forwards;
}
@keyframes link2 {
    0%{
        transform: translate(0);
    }
    100%{
        transform: translate(-280px);
    }
}

@keyframes shake {
    10%{
        transform: rotate(10deg);
    }
    20%{
        transform: rotate(-10deg);
    }
    30%{
        transform: rotate(10deg);
    }
    40%{
        transform: rotate(-10deg);
    }
}
@media screen and (max-width: 1300px) {
    @keyframes insta2 {
        0%{
            transform: translate(0);
        }
        50%{
            transform: translateY(113px);
        }
        100%{
            transform: translate(-80px,113px);
        }
    }
    @keyframes wapp2 {
        0%{
            transform: translate(0);
        }
        50%{
            transform: translateY(56px);
        }
        100%{
            transform: translate(-140px, 56px);
        }
    }
    @keyframes link2 {
        0%{
            transform: translate(0);
        }
        100%{
            transform: translate(-200px);
        }
    }
}

@media screen and (max-width: 1100px) {
    @keyframes insta2 {
        0%{
            transform: translate(0);
        }
        50%{
            transform: translateY(113px);
        }
        100%{
            transform: translate(-40px,113px);
        }
    }
    @keyframes wapp2 {
        0%{
            transform: translate(0);
        }
        50%{
            transform: translateY(56px);
        }
        100%{
            transform: translate(-100px, 56px);
        }
    }
    @keyframes link2 {
        0%{
            transform: translate(0);
        }
        100%{
            transform: translate(-160px);
        }
    }
}
@media screen and (max-width: 1000px) {
    .instaLogo, .linkLogo, .wappLogo{
        animation: none;
    }
    .instaLogoAfter, .linkLogoAfter, .wappLogoAfter{
        animation: none;
    }
}