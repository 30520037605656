#why {
    grid-area: why;
    z-index: 2;
    padding: 50px 150px 150px 150px;
    display: grid;
    gap: 80px;
}

#why-title {
    padding: 25px 0px;
    width: 600px;
    margin: 0px 0px 0px -200px;
    border: 1px solid var(--cor-azul-secundaria);
    border-radius: 0px 100px 100px 0px;
    background-image: linear-gradient(to right, #ffffff, #2268d005, #2268d00e);
    -webkit-backdrop-filter: blur(5.5px);
    backdrop-filter: blur(3.5px);
}

#why-title h1 {
    margin: 0px 0px 0px 200px;
    position: relative;
    z-index: 5;
    width: 50%;
}

#why-cards {
    justify-content: center;
    display: flex;
}

#why-cards p {
    font-size: 16px;
    color: var(--cor-azul-principal);
}

#why-card-1 h2,
#why-card-2 h2,
#why-card-3 h2,
#why-card-4 h2 {
    transition: 2s;
    background-image: linear-gradient(to right, rgb(81, 165, 255), #2c55a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}



#why-card-1 {
    background-image: url('../img/whyIMG/one.png');
    background-repeat: no-repeat;
    background-position: 110%;
    background-size: 200px;
    width: 110%;
    margin: 0px 0px 180px 50px;
    cursor: pointer;
    position: relative;
    z-index: 6;
    padding: 50px 30px;
    background-color: rgba(255, 255, 255, 0.445);
    -webkit-backdrop-filter: blur(5.5px);
    backdrop-filter: blur(5.5px);
    box-shadow: 0 8px 32px 0 #2268d036;
    transition: 0.5s ease-in-out;
    border-radius: 20px;
}

#why-card-2 {
    background-image: url('../img/whyIMG/two.png');
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 220px;
    width: 110%;
    margin: 180px 0px 0px -150px;
    cursor: pointer;
    position: relative;
    z-index: 5;
    padding: 50px 30px;
    background-color: rgba(255, 255, 255, 0.466);
    -webkit-backdrop-filter: blur(5.5px);
    backdrop-filter: blur(5.5px);
    box-shadow: 0 8px 32px 0 #2268d036;
    transition: 0.5s ease-in-out;
    border-radius: 20px;
}

#why-card-3 {
    background-image: url('../img/whyIMG/three.png');
    background-repeat: no-repeat;
    background-position: 110%;
    background-size: 220px;
    width: 110%;
    margin: 0px 0px 180px -50px;
    cursor: pointer;
    position: relative;
    z-index: 4;
    padding: 50px 30px;
    background-color: rgba(255, 255, 255, 0.473);
    -webkit-backdrop-filter: blur(5.5px);
    backdrop-filter: blur(5.5px);
    box-shadow: 0 8px 32px 0 #2268d036;
    transition: 0.5s ease-in-out;
    border-radius: 20px;
}

#why-card-4 {
    background-image: url('../img/whyIMG/four.png');
    background-repeat: no-repeat;
    background-position: 90%;
    background-size: 220px 210px;
    width: 110%;
    margin: 180px 0px 0px -50px;
    cursor: pointer;
    position: relative;
    z-index: 3;
    padding: 50px 30px;
    background-color: rgba(255, 255, 255, 0.623);
    -webkit-backdrop-filter: blur(5.5px);
    backdrop-filter: blur(5.5px);
    box-shadow: 0 8px 32px 0 #2268d036;
    transition: 0.5s ease-in-out;
    border-radius: 20px;
}

#why-card-1:hover,
#why-card-2:hover,
#why-card-3:hover,
#why-card-4:hover {
    scale: 1.1;
    background-color: white;
    z-index: 7;
}

@keyframes whySlide {
    from {
        transform: translateX(-150vw);
    }
}

.whySlide1 {
    animation: whySlide 2.3s ease-in-out;
}

.whySlide2 {
    animation: whySlide 2s ease-in-out;
}

.whySlide3 {
    animation: whySlide 1.7s ease-in-out;
}

.whySlide4 {
    animation: whySlide 1.4s ease-in-out;
}

@media screen and (max-width: 1300px) {
    #why-cards {
        justify-content: center;
        margin: 0;
        padding: 0;
        display: flex;
        gap: 10px;
    }

    #why-card-1,
    #why-card-2,
    #why-card-3,
    #why-card-4 {
        background-size: 400px;
        background-position: 20%;
        margin: 0;
        width: auto;
    }
}

@media screen and (max-width: 1200px) {
    #why {
        padding: 4% 8%;
    }
}

@media screen and (max-width: 1000px) {

    #why-card-1:hover,
    #why-card-2:hover,
    #why-card-3:hover,
    #why-card-4:hover {
        scale: 1;
        background-color: white;
        z-index: 7;
    }

    #why {
        padding: 10% 5%;
    }

    #why-cards {
        display: grid;
        gap: 20px;
    }

    #why-card-1,
    #why-card-2,
    #why-card-3,
    #why-card-4 {
        background-size: 180px;
        background-position: 100%;
        margin: 0;
        width: auto;
    }

    .whySlide1 {
        animation: none !important;
    }

    .whySlide2 {
        animation: none !important;
    }

    .whySlide3 {
        animation: none !important;
    }

    .whySlide4 {
        animation: none !important;
    }

}

@media screen and (max-width: 600px) {
    #why {
        gap: 60px;
    }
}

@media screen and (max-width: 450px) {
    #why-title {
        width: auto;
    }
}