#context{
    cursor: default;
    overflow-x: hidden;
    padding: 120px 150px;
    grid-area: context;
    height: auto;
}

.context-text{
    display: grid;
}

.context-text p{
    z-index: 2;
    font-size: 25px;
    color: var(--cor-azul-principal);
    font-weight: 600;
}

.context-btn{
    z-index: 2;
    padding: 10px 0px;
}

.context-btn a:hover{
    background-image: linear-gradient(to right, white, white);
    -webkit-background-clip: none;
    -webkit-text-fill-color: none;
    z-index: 2;
}

.context-btn button{
    backdrop-filter: blur( 5.5px );
    z-index: 2;
    width: 180px;
    border-radius: 50px;
    height: 40px;
    border: 1px solid var(--cor-azul-secundaria);
    background-image: linear-gradient(31deg,transparent, transparent);
    transition: 0.5s ease-in-out;
}
.context-btn button:hover{
    z-index: 2;
    transform: translateY(1px);
    box-shadow: 0px 10px 10px #71717199;
    border: none;
    background-image: linear-gradient(31deg,#2c55a0d0,#2268d0b9);
}

.context-btn button a{
    background-image: linear-gradient(to right, #2c55a0, #2c55a0, #2c55a0, #2c55a0, rgb(81, 165, 255),#2c55a0,#2c55a0,#2c55a0, #2c55a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 250% 250%;
    animation: gradient 10s infinite;
    z-index: 2;
    transition: 0.5s;
    height: 100%;
    align-items: center;
    font-weight: 500;
    display: grid;
    width: 100%;
    color: var(--cor-azul-secundaria);
    font-size: 18px;
}
 
.context-cards-1{
    grid-template-columns: auto auto auto;
    z-index: 2; 
    padding: 20px;
    gap: 30px;
    display: grid;
    justify-content: center;
}

#context-card-1{
    grid-column-start: 1;
    grid-column-end: 2;
    cursor: pointer;
    margin: 50px auto auto auto;
    position: relative;
    z-index: 2;
    width: auto;
    display: grid;
    align-items: center;
    padding: 30px;
    border-radius: 20px;
    background-color: rgba( 255, 255, 255, 0.25 );
    backdrop-filter: blur( 5.5px );
    box-shadow: 0 8px 32px 0 #2268d036;
    transition: 0.4s ease-in-out;
}

#context-card-1:hover{
    background-color: var(--cor-branca);
    z-index: 2;
    scale: 1.06;
    box-shadow: none;
    transition: 0.4s ease-in-out;
}

#context-card-1 img{
    background-image: linear-gradient(to right, rgb(81, 165, 255),#2c55a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#context-card-1 h2{
    background-image: linear-gradient(to right, rgb(81, 165, 255),#2c55a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#context-card-1 p{
    z-index: 2;
    font-size: 18px !important;
    color: var(--cor-azul-principal);

}

#context-card-2{
    grid-column-start: 2;
    grid-column-end: 3;
    cursor: pointer;
    position: relative;
    margin: 150px auto auto auto; 
    z-index: 2;
    width: auto;
    display: grid;
    align-items: center;
    padding: 30px;
    border-radius: 20px;
    background-color: rgba( 255, 255, 255, 0.25 );
    backdrop-filter: blur( 5.5px );
    box-shadow: 0 8px 32px 0 #2268d036;
    transition: 0.4s;
}

#context-card-2:hover{
    z-index: 2;
    scale: 1.06;
    box-shadow: none;
    transition: 0.4s  ease-in-out;
    background-color: var(--cor-branca);
}

#context-card-2 h2{
    background-image: linear-gradient(to right, rgb(81, 165, 255),#2c55a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#context-card-2 p{
    z-index: 2;
    font-size: 18px !important;
    color: var(--cor-azul-principal);
}

.context-cards-2{
    z-index: 2;
    display: grid;
    justify-content: center;
}

#context-card-3{
    grid-column-start: 3;
    grid-column-end: 4;
    cursor: pointer;
    position: relative;
    margin: 0px auto auto auto;
    z-index: 3;
    width: auto;
    display: grid;
    align-items: center;
    padding: 30px;
    border-radius: 20px;
    background-color: rgba( 255, 255, 255, 0.25 );
    backdrop-filter: blur( 5.5px );
    box-shadow: 0 8px 32px 0 #2268d036;
    transition: 0.5s;
}

#context-card-3:hover{
    z-index: 2;
    scale: 1.06;
    box-shadow: none;
    transition: 0.4s  ease-in-out;
    background-color: var(--cor-branca);

}

#context-card-3 h2{
    background-image: linear-gradient(to right, rgb(81, 165, 255),#2c55a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#context-card-3 p{
    z-index: 2;
    font-size: 18px !important;
    color: var(--cor-azul-principal);
}

.contextBorder1{
    z-index: 2;
    position: relative;
    left: 83vw;
    border: 1px solid var(--cor-azul-principal);
    border-radius: 100px;
    width: 200px;
    height: 30px;
    top: 250px;
}

.contextBorder2{
    z-index: 2;
    position: relative;
    left: 78vw;
    border: 1px solid var(--cor-azul-principal);
    border-radius: 100px;
    width: 250px;
    height: 50px;
    top: 280px;
}

.contextBorder3{
    z-index: 2;
    position: relative;
    left: -300px;
    border: 1px solid var(--cor-azul-principal);
    border-radius: 100px;
    width: 250px;
    height: 50px;
    top: 600px;
}

.contextSlide1{
    animation: contextSlide 2.2s ease-in-out;
}
.contextSlide2{
    animation: contextSlide 1.8s ease-in-out;
}
.contextSlide3{
    animation: contextSlide 1.4s ease-in-out;
}

@keyframes contextSlide {
    from {
        transform: translateX(-200vw);
    }
}

@media screen and (max-width: 1200px){
    #context{
        padding: 8% 8% 4% 8%;
    }
}

@media screen and (max-width: 1000px){
    .context-btn button:hover{
        z-index: 2;
        transform: translateY(0px);
        box-shadow: 0px 10px 10px #71717199;
        border: none;
        background-image: linear-gradient(31deg,#2c55a0d0,#2268d0b9);
    }
    #context-card-1:hover, #context-card-2:hover, #context-card-3:hover{
        scale: 1 !important;
    }
    #context{
        padding: 10% 5%;
    }
    .context-btn button{
        backdrop-filter: none;
    }
    .context-cards-1{
        grid-template-columns: auto;
        grid-template-rows: auto;
        padding: 2% 2% 4% 2%;
        display: grid;
        gap: 20px;
    }   
    #context-card-1{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
        animation: none;
        margin: 0;
        gap: 5px;
        width: 80vw;
    }
    #context-card-2{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
        animation: none;
        margin: 0;
        gap: 5px;
        width: 80vw;
    }
    
    #context-card-3{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
        animation: none;
        margin: 0;
        gap: 5px;
        width: 80vw;
    }

    .ourServicesSlide1, .ourServicesSlide2, .ourServicesSlide3, .ourServicesSlide4{
        animation: none !important;
    }
}

@media screen and (max-width: 600px){
    .context-btn{
        padding: 15px 0px;
    }
    .context-cards-1{
        padding: 20px;
    }
}