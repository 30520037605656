.hero {
    background-color: transparent;
    display: grid;
    height: 100vh;
    overflow: hidden;
}

.hero video {
    z-index: 0;
    width: 100%;
    grid-area: hero;
    position: absolute;
    height: 100vh;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    border-radius: 0px 0px 20px 20px;
}

.hero-cont {
    z-index: 3;
    padding: 0% 5% 0% 5%;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -100px;
}

.hero-cont h1 {
    font-weight: 200 !important;
    font-size: 45px;
    color: white;
}

.hero-cont p {
    padding: 2% 0%;
    font-size: 28px;
    font-weight: 200 !important;
    color: white;
}

.arrow {
    z-index: 3;
    scale: 0.8;
    bottom: 50px;
    justify-self: center !important;
    position: absolute;
    display: grid;
}

.arrow2 {
    z-index: 3;
    scale: 0.9;
    bottom: 40px;
    position: absolute;
    justify-self: center !important;
    display: grid;
}

.arrow3 {
    z-index: 3;
    bottom: 30px;
    position: absolute;
    justify-self: center !important;
    display: grid;
}

.arrow img {
    animation: isBouncing 1s ease-in-out infinite alternate;
    opacity: 0.2;
}

.arrow2 img {
    animation: isBouncing2 1s ease-in-out infinite alternate 0.1s;
    opacity: 0.6;
}

.arrow3 img {
    animation: isBouncing3 1s ease-in-out infinite alternate 0.2s;
    opacity: 1;
}

@media screen and (max-width: 600px) {
    .hero-cont h1 {
        font-size: 35px;
    }
    .hero-cont {
        margin-top: -150px;
    }
    .arrow{
        bottom: 25px;
        scale: 0.5;
    }
    .arrow2{
        bottom: 20px;
        scale: 0.6;
    }
    .arrow3{
        bottom: 15px;
        scale: 0.7;
    }
}
@media screen and (max-width: 425px) {
    .arrow, .arrow2, .arrow3{
        left: 44.3%;
    }
}