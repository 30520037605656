.particles-wrapper #tsparticles canvas {
    pointer-events: auto !important;
    width: 50% !important;
    height: 200px !important;
    z-index: 9999 !important;
    top: 50px !important;
    left: 50px !important;
    position: initial !important;
}

.particles-hidden {
    opacity: 0;
    pointer-events: none;
    animation: fadeOut 0.2s ease;
}

@keyframes fadeOut {
    from {
        opacity: 2;
    }

    to {
        opacity: 0;
    }
}



.particulas-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}


.particle2 {
    z-index: 1;
    position: absolute;
    left: -250px;
    top: 1100px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 60px;
    height: 12px;
    animation: particle2 70s infinite alternate linear 10s;
}

.particle3 {
    z-index: 1;
    position: absolute;
    top: 1200px;
    left: -200px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 50px;
    height: 11px;
    animation: particle3 90s infinite alternate linear;
}

.particle4 {
    left: -250px;
    z-index: 1;
    position: absolute;
    top: 1300px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 45px;
    height: 8px;
    animation: particle4 50s infinite alternate linear 7s;
}

.particle5 {
    left: -250px;
    z-index: 1;
    position: absolute;
    top: 1400px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 45px;
    height: 8px;
    animation: particle5 70s infinite alternate linear 9s;
}

.particle6 {
    z-index: 1;
    position: absolute;
    left: -250px;
    top: 1500px;
    background-color: var(--cor-azul-principal);
    border-radius: 30px;
    width: 60px;
    height: 12px;
    animation: particle6 100s infinite alternate linear 16s;
}

.particle7 {
    left: -250px;
    z-index: 1;
    position: absolute;
    top: 1600px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 150px;
    height: 20px;
    animation: particle7 80s infinite alternate linear 8s;
}

.particle8 {
    left: -250px;
    z-index: 1;
    position: absolute;
    top: 1700px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 150px;
    height: 25px;
    animation: particle8 90s infinite alternate linear 15s;
}

.particle9 {
    z-index: 1;
    position: absolute;
    top: 1800px;
    left: -400px;
    background-color: var(--cor-azul-principal);
    border-radius: 30px;
    width: 50px;
    height: 11px;
    animation: particle1 100s infinite alternate linear 3s;
}

.particle10 {
    z-index: 1;
    position: absolute;
    left: -250px;
    top: 1900px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 60px;
    height: 12px;
    animation: particle2 50s infinite alternate linear 10s;
}

.particle11 {
    z-index: 1;
    position: absolute;
    top: 2000px;
    left: -400px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 50px;
    height: 11px;
    animation: particle3 70s infinite alternate linear;
}

.particle12 {
    left: -250px;
    z-index: 1;
    position: absolute;
    top: 2100px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 45px;
    height: 8px;
    animation: particle4 90s infinite alternate linear 7s;
}

.particle13 {
    left: -250px;
    z-index: 1;
    position: absolute;
    top: 2200px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 45px;
    height: 8px;
    animation: particle5 100s infinite alternate linear 9s;
}

.particle14 {
    z-index: 1;
    position: absolute;
    left: -250px;
    top: 2300px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 60px;
    height: 12px;
    animation: particle6 60s infinite alternate linear 16s;
}

.particle15 {
    left: -350px;
    z-index: 1;
    position: absolute;
    top: 2400px;
    background-color: var(--cor-azul-principal);
    border-radius: 30px;
    width: 150px;
    height: 20px;
    animation: particle7 50s infinite alternate linear 8s;
}

.particle16 {
    left: -350px;
    z-index: 1;
    position: absolute;
    top: 2550px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 150px;
    height: 25px;
    animation: particle8 100s infinite alternate linear 15s;
}

.particle17 {
    z-index: 1;
    position: absolute;
    top: 2650px;
    left: -400px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 50px;
    height: 11px;
    animation: particle1 80s infinite alternate linear 3s;
}

.particle18 {
    z-index: 1;
    position: absolute;
    left: -250px;
    top: 2750px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 60px;
    height: 12px;
    animation: particle2 50s infinite alternate linear 10s;
}

.particle19 {
    z-index: 1;
    position: absolute;
    top: 2850px;
    left: -400px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 50px;
    height: 11px;
    animation: particle3 50s infinite alternate linear;
}

.particle20 {
    left: -250px;
    z-index: 1;
    position: absolute;
    top: 2950px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 45px;
    height: 8px;
    animation: particle4 70s infinite alternate linear 7s;
}

.particle21 {
    left: -250px;
    z-index: 1;
    position: absolute;
    top: 3100px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 45px;
    height: 8px;
    animation: particle5 100s infinite alternate linear 9s;
}

.particle22 {
    z-index: 1;
    position: absolute;
    left: -250px;
    top: 3200px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 60px;
    height: 12px;
    animation: particle6 90s infinite alternate linear 16s;
}

.particle23 {
    left: -350px;
    z-index: 1;
    position: absolute;
    top: 3300px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 150px;
    height: 20px;
    animation: particle7 100s infinite alternate linear 8s;
}

.particle24 {
    left: -350px;
    z-index: 1;
    position: absolute;
    top: 3400px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 150px;
    height: 25px;
    animation: particle8 60s infinite alternate linear 15s;
}

.particle25 {
    z-index: 1;
    position: absolute;
    top: 3500px;
    left: -400px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 50px;
    height: 11px;
    animation: particle1 70s infinite alternate linear 3s;
}

.particle26 {
    z-index: 1;
    position: absolute;
    left: -250px;
    top: 3600px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 60px;
    height: 12px;
    animation: particle2 80s infinite alternate linear 10s;
}

.particle27 {
    z-index: 1;
    position: absolute;
    top: 3750px;
    left: -400px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 50px;
    height: 11px;
    animation: particle3 90s infinite alternate linear;
}

.particle28 {
    left: -250px;
    z-index: 1;
    position: absolute;
    top: 3850px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 45px;
    height: 8px;
    animation: particle4 50s infinite alternate linear 7s;
}

.particle29 {
    left: -250px;
    z-index: 1;
    position: absolute;
    top: 4000px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 45px;
    height: 8px;
    animation: particle5 100s infinite alternate linear 9s;
}

.particle30 {
    z-index: 1;
    position: absolute;
    left: -250px;
    top: 4100px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 60px;
    height: 12px;
    animation: particle6 70s infinite alternate linear 16s;
}

.particle31 {
    left: -350px;
    z-index: 1;
    position: absolute;
    top: 4200px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 150px;
    height: 20px;
    animation: particle7 80s infinite alternate linear 20s;
}

.particle32 {
    left: -350px;
    z-index: 1;
    position: absolute;
    top: 4300px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 150px;
    height: 25px;
    animation: particle8 50s infinite alternate linear 30s;
}

.particle33 {
    z-index: 1;
    position: absolute;
    top: 4400px;
    left: -400px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 50px;
    height: 11px;
    animation: particle1 60s infinite alternate linear 3s;
}

.particle34 {
    z-index: 1;
    position: absolute;
    left: -250px;
    top: 4500px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 60px;
    height: 12px;
    animation: particle2 100s infinite alternate linear 10s;
}

.particle35 {
    z-index: 1;
    position: absolute;
    top: 4600px;
    left: -400px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 50px;
    height: 11px;
    animation: particle3 100s infinite alternate linear;
}

.particle36 {
    left: -250px;
    z-index: 1;
    position: absolute;
    top: 4700px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 45px;
    height: 8px;
    animation: particle4 80s infinite alternate linear 7s;
}

.particle37 {
    left: -250px;
    z-index: 1;
    position: absolute;
    top: 4800px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 45px;
    height: 8px;
    animation: particle5 90s infinite alternate linear 9s;
}

.particle38 {
    z-index: 1;
    position: absolute;
    left: -250px;
    top: 4900px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 60px;
    height: 12px;
    animation: particle6 60s infinite alternate linear 16s;
}

.particle39 {
    left: -350px;
    z-index: 1;
    position: absolute;
    top: 5000px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 150px;
    height: 20px;
    animation: particle7 70s infinite alternate linear 20s;
}

.particle40 {
    left: -350px;
    z-index: 1;
    position: absolute;
    top: 5100px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 150px;
    height: 25px;
    animation: particle8 100s infinite alternate linear 30s;
}

.particle41 {
    z-index: 1;
    position: absolute;
    top: 5200px;
    left: -400px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 50px;
    height: 11px;
    animation: particle1 90s infinite alternate linear 3s;
}

.particle42 {
    z-index: 1;
    position: absolute;
    left: -250px;
    top: 5300px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 60px;
    height: 12px;
    animation: particle2 60s infinite alternate linear 10s;
}

.particle43 {
    z-index: 1;
    position: absolute;
    top: 5400px;
    left: -400px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 50px;
    height: 11px;
    animation: particle3 80s infinite alternate linear;
}

.particle44 {
    left: -250px;
    z-index: 1;
    position: absolute;
    top: 5500px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 45px;
    height: 8px;
    animation: particle4 50s infinite alternate linear 7s;
}

.particle45 {
    left: -250px;
    z-index: 1;
    position: absolute;
    top: 5600px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 45px;
    height: 8px;
    animation: particle5 100s infinite alternate linear 9s;
}

.particle46 {
    z-index: 1;
    position: absolute;
    left: -250px;
    top: 5700px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 60px;
    height: 12px;
    animation: particle6 50s infinite alternate linear 16s;
}

.particle47 {
    left: -350px;
    z-index: 1;
    position: absolute;
    top: 6000px;
    background-color: var(--cor-branca);
    border-radius: 30px;
    width: 150px;
    height: 20px;
    animation: particle7 50s infinite alternate linear 20s;
}

.particle48 {
    left: -350px;
    z-index: 1;
    position: absolute;
    top: 6200px;
    background-color: var(--cor-azul-secundaria);
    border-radius: 30px;
    width: 150px;
    height: 25px;
    animation: particle8 80s infinite alternate linear 30s;
}

@keyframes particle1 {
    to {
        transform: translateX(200vw);
    }
}

@keyframes particle2 {
    to {
        transform: translateX(115vw);
    }
}

@keyframes particle3 {
    to {
        transform: translateX(200vw);
    }
}

@keyframes particle4 {
    to {
        transform: translateX(115vw);
    }
}

@keyframes particle5 {
    to {
        transform: translateX(200vw);
    }
}

@keyframes particle6 {
    to {
        transform: translateX(115vw);
    }
}

@keyframes particle7 {
    to {
        transform: translateX(200vw);
    }
}

@keyframes particle8 {
    to {
        transform: translateX(115vw);
    }
}

@media screen and (max-width: 768px) {

    .particle1,
    .particle2,
    .particle3,
    .particle4,
    .particle5,
    .particle6,
    .particle7,
    .particle8,
    .particle9,
    .particle10,
    .particle11,
    .particle12,
    .particle13,
    .particle14,
    .particle15,
    .particle16,
    .particle17,
    .particle18,
    .particle19,
    .particle20,
    .particle21,
    .particle22,
    .particle23,
    .particle24,
    .particle25,
    .particle26,
    .particle27,
    .particle28,
    .particle29,
    .particle30,
    .particle31,
    .particle32,
    .particle33,
    .particle34,
    .particle35,
    .particle36,
    .particle37,
    .particle38,
    .particle39,
    .particle40,
    .particle41,
    .particle42,
    .particle43,
    .particle44,
    .particle45,
    .particle46,
    .particle47,
    .particle48 {
        display: none;
    }
}