#aboutHeader {
    grid-template-columns: 50% 50%;
    grid-template-rows: 33% 33% 33%;
    padding: 100px 150px 0px 150px;
    grid-area: aboutHeader;
    animation: gradient 8s infinite alternate;
    background-image: linear-gradient(30deg, #1A3361, #244787, #2C55A0, #2F5BAD, #407DED);
    background-size: 300% 300%;
    border-radius: 0px 0px 0px 150px;
    display: grid;
    height: 60vh;
}

#particle1 {
    top: 100px;
    left: -10px;
    position: absolute;
    height: 20px;
    width: 100px;
    border-radius: 30px;
    border: 1px solid #407DED;
}

#particle2 {
    top: 140px;
    left: -20px;
    position: absolute;
    height: 40px;
    width: 300px;
    border-radius: 30px;
    border: 1px solid var(--cor-azul-secundaria);
}

.aboutHeader-title {
    height: fit-content;
    z-index: 2;
    width: 100%;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    position: relative;
}

.aboutHeader-title h1 {
    width: fit-content;
    font-size: 45px;
    color: white;
}

.aboutHeader-cards {
    display: grid;
    z-index: 0;
    position: relative;
    left: 50px;
    top: -50px;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
}

.aboutHeader-cards img {
    width: 720px !important;
}

@media screen and (max-width: 1600px) {
    .aboutHeader-cards img {
        width: 620px !important;
    }
}

@media screen and (max-width: 1000px) {
    #aboutHeader {
        padding: 10% 5% 0% 5%;
    }

    #particle3 {
        display: none;
    }
}

@media screen and (max-width: 790px) {
    #aboutHeader {
        grid-template-columns: 100%;
        align-items: center;
    }

    .aboutHeader-cards {
        grid-column-end: 2;
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .aboutHeader-title h1 {
        font-size: 35px !important;
    }

}