#our-medias {
    background-image: url('https://singlec.com.br/imagens-site/midia_background.png');
    z-index: 10;
    text-align: center;
    padding: 50px 150px 100px 150px;
    grid-area: medias;
    display: grid;
    justify-content: center;
}

#medias-title {
    padding: 0px 0px 60px 0px;
    display: grid;
    width: 100%;
}

#medias-title p {
    padding: 20px 0px 0px 0px;
}

#underline {
    display: grid;
    justify-self: center;
    width: 100px;
    height: 3px;
    border-radius: 10px;
    background-color: var(--cor-azul-secundaria);
}

#feed {
    display: grid;
    background-color: white;
    padding: 20px 8px 15px 8px;
    border-radius: 10px;
    gap: 20px;
}

#carousel {
    justify-content: center;
    max-width: 1024px;
    justify-content: center;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: auto auto auto auto;
    display: grid;
    width: 100%;
    gap: 8px;
}

#singlec {
    justify-self: center;
    width: 20%;
    border-radius: 30px;
    background-color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 2px;
}

#singlec a {
    display: flex;
    align-items: center;
}

#singlec img {
    height: 100%;
    padding: 3px;
    width: 30px;
}

#singlec p {
    font-size: 18px;
}

#follow-us {
    justify-content: center;
    display: grid;
}

#follow-us a {
    align-items: center;
    background-image: linear-gradient(to right, #2c55a0, #2c55a0, #2c55a0, #2c55a0, rgb(81, 165, 255), #2c55a0, #2c55a0, #2c55a0, #2c55a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 250% 250%;
    animation: gradient 10s infinite;
    width: fit-content;
    border: 1px solid var(--cor-azul-principal);
    border-radius: 10px;
    padding: 5px 10px;
    gap: 10px;
    display: flex;
    transition: 0.5s ease-in-out;
}

#follow-us a:hover {
    scale: 1.1;
}

#follow-us img {
    width: 20px;
    height: 20px;
}

#follow-us a p {
    font-size: 17px;
    color: var(--cor-azul-principal);
}

.card-media {
    box-shadow: 0 8px 32px 0 #2268d036;
    justify-content: center;
    display: grid;
    grid-template-areas: 'card';
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    align-items: center;
    width: auto;
    transition: 0.4s ease;
}

.card-media1 {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 4;
}

.media-text {
    transition: 0.4s ease-in-out;
    align-items: center;
    text-align: center;
    overflow: hidden;
    display: grid;
    /*     display: -webkit-box;
 */
    grid-area: card;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    height: 100%;
    min-width: 0px;
    max-width: 100%;
    line-height: 1.3;
}

.media-text p {
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: -webkit-box;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: normal;
    overflow-wrap: normal;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    line-height: 1.3;
    opacity: 0;
    width: 80%;
    justify-self: center;
    max-height: 148px;
    z-index: 5;
    color: var(--cor-branca);
    transition: 0.5s ease-in-out;
}

.text-video {
    display: none;
}

.card-media img,
video {
    z-index: 10;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    display: grid;
    grid-area: card;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    transition: 0.5s ease-in-out;
}

.card-media:hover .media-text {
    background-color: #2c55a0dc;
    z-index: 10;
}

.card-media:hover .media-text p {
    z-index: 11;
    opacity: 100;
}

.card-media:hover.card-media img {
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.1);
    filter: blur(1px);
}

@media screen and (max-width: 1200px) {
    #our-medias {
        padding: 4% 8%;
    }
}

@media screen and (max-width: 1000px) {
    #our-medias {
        padding: 10% 5%;
    }
}

@media screen and (max-width: 768px) {
    .card-media:hover .media-text {
        display: none;
    }

    .card-media:hover.card-media img {
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
        filter: none;
    }

    #carousel {
        grid-template-rows: auto;
        grid-template-columns: auto auto;
    }

    .card-media1 {
        display: grid;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    .card-media8 {
        display: none;
    }
}

@media screen and (max-width: 425px) {
    .text-video {
        display: grid;
    }

    #feed {
        padding: 20px 0px 15px 0px;
    }

    .card-media:hover .media-text {
        display: grid;
        background-color: white;
        z-index: 10;
    }

    #carousel {
        gap: 30px;
        grid-template-columns: auto;
    }

    .card-media img {
        border-radius: 0px;
        grid-area: card;
    }

    .card-media video {
        border-radius: 0px;
        grid-area: card;
    }

    .card-media:hover.card-media img {
        scale: 1;
        filter: none !important;
    }

    .card-media:hover.card-media video {
        filter: none !important;
    }

    .media-text {
        padding: 20px;
        grid-area: card-text;
    }

    .media-text p {
        font-size: 15px !important;
        color: var(--cor-azul-principal);
        font-weight: 600;
        max-height: none;
        width: 100%;
        opacity: 100;
    }

    .card-media {
        border-radius: 0px;
        grid-template-areas:
            'card'
            'card-text'
        ;
    }

    .card-media1 {
        grid-column-start: 1;
        grid-column-end: 2;
    }
}