#arch {
    padding: 150px 0px 0px 0px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-area: arch;
}

.arch-title {
    height: fit-content;
    padding: 0px 0px 0px 150px;
    grid-column-start: 1;
    grid-column-end: 2;
    display: grid;
    gap: 20px;
}

.arch-title h2 {
    font-size: 25px;
    color: var(--cor-azul-secundaria);
}

.arch-title p {
    font-size: 18px;
}

.arch-img1 {
    width: fit-content;
    justify-self: center;
    padding: 80px 150px 0px 0px;
    grid-column-start: 2;
    grid-column-end: 3;
}

.arch-img1 img {
    border-radius: 30px;
}

.arch-img2 {
    width: fit-content;
    justify-self: left;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 150px;
    grid-column-start: 1;
    grid-column-end: 2;
}

.arch-img2 img {
    border-radius: 30px;
}

.arch-text {
    padding: 100px 150px 100px 0px;
    grid-column-start: 2;
    grid-column-end: 3;
}

.arch-text p {
    font-size: 18px;
}

@media screen and (max-width:1600px) {
    .arch-img1 img {
        width: 500px;
    }

    .arch-img2 {
        margin: 50px 0px 0px 0px;
    }

    .arch-img2 img {
        width: 500px;
        height: 350px;
    }
}

@media screen and (max-width:1300px) {
    .arch-title {
        gap: 10px;
    }

    .arch-img1 {
        align-self: center;
        padding: 50px 0px 0px 0px;
    }

    .arch-img1 img {
        width: 500px;
    }

    .arch-img2 {
        padding: 0px 0px 0px 50px;
        margin: 50px 0px 0px 0px;
    }

    .arch-text {
        padding: 100px 50px 100px 0px;
    }
}

@media screen and (max-width: 1100px) {
    .arch-title {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 2;
        padding: 0px 50px;
    }

    .arch-img1 {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 2;
    }

    .arch-text {
        grid-row-start: 3;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 2;
        padding: 50px;
    }

    .arch-img2 {
        justify-self: center;
        margin: 0px;
        padding: 0px;
        grid-row-start: 4;
        grid-row-end: 5;
        grid-column-start: 1;
        grid-column-end: 2;
    }

    #arch {
        grid-template-columns: 100%;
    }

    .arch-text {
        padding: 20px 50px;
    }
}

@media screen and (max-width: 800px) {
    #arch {
        padding: 100px 0px 0px 0px;
    }
}

@media screen and (max-width: 600px) {
    .arch-img1 img {
        width: 400px;
    }

    .arch-img2 img {
        width: 400px;
        height: 250px;
    }
}