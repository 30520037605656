footer{
    z-index: 10;
    background-image: linear-gradient(to right, #4a7cd8,#2c55a0);
    border-radius: 0px;
    height: auto;
    padding: 20px 150px;
    display: flex;
    grid-area: footer;
    align-items: center;
    justify-content: space-between;
}

#footer-logo{
    font-size: 16px;
    align-items: center;
    display: grid;
}
#footer-logo2{
    width: 180px;
}
#footer-text{
    text-align: center;
    justify-content: center;
    color: var(--cor-branca);
    font-size: 16px;
    font-weight: 300;
    display: grid;
    align-items: center;
}
#footer-text p{
    font-size: 18px;
    color: white;
    font-weight: 500;
}
@media  screen and (max-width: 1300px) {
    footer{
        padding: 20px 100px;
    }
}
@media  screen and (max-width: 1200px) {
    footer{
        padding: 20px 50px;
    }
    #footer-text p{
        font-size: 16px !important; 
    }
}
@media  screen and (max-width: 1000px) {
    footer{
        justify-content: center;
    }
    #footer-logo{
        display: none;
    }
    #footer-logo2{
        display: none;
    }
}
@keyframes border{
    from{
        transform: translateY(400px);
    }
} 
@media screen and (max-width: 768px) {
    footer{
        padding: 20px 50px 20px 50px;
        display: grid;
        gap: 20px;
        justify-content: center;
    }
}
