#questions{
    background-image: url('../img/faqIMG/backgroud.png');
    background-repeat: no-repeat;
    background-position: 50%;
    padding: 120px 150px;
    display: grid;
    grid-area: questions;
}
.questionsTitle{
    text-align: center;
    display: grid;
    justify-content: center;
}
.questionsTitle h1{
    font-size: 35px !important;
}
.underlineQuestions{
    justify-self: center;
}
.questionsCards{
    padding: 50px 0px;
    gap: 20px;
    display: grid;
    justify-content: center;
}
.card{
    align-items: center;
    height: fit-content;
    padding: 5px 10px;
    transition: 0.5s;
    display: grid;
    border-radius: 30px;
    border: 1px solid #4989ff;
}
.card.active{
    align-items:start;
    background-color: #2268d0;
}
.question{
    height: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0px;
}

.number{
    display: grid;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(45deg, #584ffee5, #4989ff);
    border-radius: 30px;
    width: 45px;
    height: 35px;
}
.number.active{
    background-image: linear-gradient(45deg, var(--cor-azul-principal), var(--cor-azul-principal));
}
.number h2{
    font-size: 22px;
    color: white;
}
.question h1{
    padding: 0px 0px 0px 10px !important;
    grid-column-start: 1;
    grid-column-end: 2;
    font-size: 22px;
}
.question.active h1{
    color: white;
}
.questionBtn{
    cursor: pointer;
    border: none;
    background-color: transparent;
    width: 50px;
    height: 50px;
    display: grid;
    justify-content: center;
    align-items: center;
    justify-self: center;
}
.btnIcon{
    transition: 0.5s;
    transform: rotate(0);
}
.btnIcon.active{
    filter: brightness(100);
    transition: 0.5s;
    transform: rotate(180deg);
}
.answer{
    padding: 0px 50px;
    max-width: 800px;
    display: none;
}
.answer.active{
    display: grid;
}
.answer p{
    padding: 20px 0px;
    font-weight: 400;
    color: white;
    font-size: 20px;
}

@media screen and (max-width: 1000px) {
    #questions{
        padding: 10% 5%;
    }    
}
@media screen and (max-width: 800px){
    .card10.card.active{
        gap: 10px;
    }
}

@media screen and (max-width: 768px){

}

@media screen and (max-width: 610px){
    .questionsTitle h1{
        font-size: 30px !important;
    }
    .card.active{
        padding: 20px;
    }
    .question h1{
        font-size: 20px !important;
    }
    .answer.active{
        padding: 0px 20px;
    }
    .answer p{
        padding-top: 20px;
        font-size: 18px !important;
    }
}
@media screen and (max-width: 425px){    
    .questionsTitle h1{
        font-size: 28px !important;
    }
    .answer p{
        padding-top: 30px !important;
    }
    .card{
        padding-left: 5px;
    }
}