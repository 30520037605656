#particle6{
    top: 100px;
    left: -10px;
    position:absolute;
    height: 20px;
    width: 100px;
    border-radius: 30px;
    border: 1px solid var(--cor-azul-secundaria);
}
#particle7{
    top: 140px;
    left: -20px;
    position:absolute;
    height: 40px;
    width: 200px;
    border-radius: 30px;
    border: 1px solid #407DED;
}
#platformsHeader{
    display: grid;
    grid-area: platformsHeader;
    grid-template-columns: 50% 50%;
    grid-template-rows: 33% 33% 33%;
    padding: 100px 150px 0px 150px;
    animation: gradient 8s infinite alternate;
    background-image: linear-gradient(30deg, #1A3361, #244787, #2C55A0, #2F5BAD, #407DED);
    background-size: 300% 300%;
    border-radius: 0px 0px 0px 150px;
    height: 50vh;
}
.platformsHeader-title{
    width: fit-content;
    height: fit-content;
    display: grid;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
}
.platformsHeader-title h1{
    font-weight:600;
    width: fit-content;
    font-size: 45px;
    color: white;
}
.platformsHeader-img{
    position: relative;
    top: -50px;
    left: 50px;
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
}
@media screen and (max-width: 1600px){
    .platformsHeader-img img{
        width: 720px !important;
    }
}
@media screen and (max-width: 1000px) {
    #platformsHeader{
        padding: 10% 5% 0% 5% !important;
    }
    #particle8{
        display: none;
    }   
}
@media screen and (max-width: 800px){
    #platformsHeader{
        grid-template-columns: 100%;
        align-items: center;
    }
    .platformsHeader-img{
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .platformsHeader-title h1{
        font-size: 35px !important;
    }
}
@media screen and (max-width: 425px) {
    #particle7{
        top: 100px !important;
    }
}
