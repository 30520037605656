#budget {
    display: grid;
    background-image: url('https://singlec.com.br/imagens-site/budget.jpg');
    background-repeat: no-repeat;
    background-position: 100% 80%;
    background-size: cover;
    border-radius: 50px 50px 0px 0px;
    z-index: 2;
    grid-area: budget;
    padding: 80px 150px;
}

#budget-content {
    justify-self: end;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.349);
    backdrop-filter: blur(10.5px);
    width: 600px;
    display: grid;
    padding: 50px 50px;
    gap: 50px;
}

#budget-title h1 {
    background-image: linear-gradient(to right, #2c55a0, #2c55a0, #2c55a0, #2c55a0, rgb(81, 165, 255), #2c55a0, #2c55a0, #2c55a0, #2c55a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 250% 250%;
    animation: gradient 10s infinite;
}

#budget-title p {
    background-image: linear-gradient(to right, #2c55a0, #2c55a0, #2c55a0, #2c55a0, rgb(81, 165, 255), #2c55a0, #2c55a0, #2c55a0, #2c55a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 250% 250%;
    animation: gradient 10s infinite;
    width: 100%;
    font-size: 25px;
    color: var(--cor-azul-principal);
}

#budget-form {
    width: 100%;
    display: grid;
    gap: 10px;
}

#form-item {
    display: grid;
    position: relative;
}

.alert {
    border-radius: 30px;
    text-align: center;
    background-color: #ff8000a2;
}

.alert p {
    font-weight: 500;
    color: white;
    font-size: 18px;
}

.success {
    border-radius: 30px;
    text-align: center;
    background-color: rgba(0, 128, 0, 0.712);
}

.success p {
    color: white;
    font-weight: 500;
    font-size: 18px;
}

.input {
    background-color: rgba(255, 255, 255, 0.171);
    backdrop-filter: blur(5.5px);
    transition: 0.4s ease-in-out;
    outline: none;
    height: 30px;
    border: 0.5px solid #639aff4b;
    border-radius: 15px;
    padding: 5px 15px;
    border-image: none;
    font-size: 16px;
    font-weight: 600;
    color: var(--cor-azul-principal);
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.input-message {
    padding-bottom: 80px;
}

::placeholder {
    position: relative;
    left: 0;
    transition: 0.5s;
    opacity: 100;
    font-size: 18px;
    font-weight: 400;
    color: #2c55a0;
}

.input:focus::placeholder {
    opacity: 0;
    left: 60px;
}

.label {
    background-image: linear-gradient(to right, rgb(49, 149, 255), #3c5fa0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    overflow: hidden;
    transition: 0.4s;
    padding: 5px;
    width: fit-content;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    top: 2px;
    left: 15px;
    pointer-events: none;
    color: var(--cor-azul-principal);
}


.input:focus {
    border: 1px solid var(--cor-azul-principal);
}

.form-btn {
    cursor: pointer;
    border: none;
    border-radius: 15px;
    justify-self: center;
    height: 35px;
    align-items: center;
    transition: 0.5s;
    z-index: 2;
    font-weight: 600;
    display: grid;
    width: 100%;
    color: var(--cor-azul-principal);
    font-size: 18px;
}

@media screen and (max-width: 1000px) {
    #budget {
        padding: 10% 5%;
    }

}

@media screen and (max-width: 800px) {
    #budget-content {
        width: auto;
    }
}

@media screen and (max-width: 600px) {
    #budget-content {
        text-align: center;
        padding: 10px;
    }

    #budget-title p {
        width: 100%;
    }
}